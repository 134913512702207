// extracted by mini-css-extract-plugin
export var active = "solutions-list-module--active--NE14x";
export var arrowCard = "solutions-list-module--arrow-card--FlcOM";
export var article = "solutions-list-module--article--15etU";
export var articles = "solutions-list-module--articles--4z5Rp";
export var card = "solutions-list-module--card--jl-dd";
export var carousel = "solutions-list-module--carousel--xvQer";
export var categories = "solutions-list-module--categories---PYbr";
export var category = "solutions-list-module--category--YsSTA";
export var container = "solutions-list-module--container--GD8Ov";
export var content = "solutions-list-module--content---HvZS";
export var cover = "solutions-list-module--cover--WHNxF";
export var image = "solutions-list-module--image--v6VT0";
export var link = "solutions-list-module--link--aE6Ey";
export var name = "solutions-list-module--name--drnVV";
export var paging = "solutions-list-module--paging--BvAUd";
export var right = "solutions-list-module--right--ETUN9";
export var summary = "solutions-list-module--summary--90IUr";
export var tagCategory = "solutions-list-module--tag-category--TBaiG";
export var title = "solutions-list-module--title--cixOK";