import React from 'react';

export default function LeftArrow({className}: {className?: string}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22.69 39.72"
      height="1em"
      className={className}
    >
      <g id="图层_2" data-name="图层 2">
        <g id="design">
          <g id="第二屏">
            <polyline
              fill="none"
              stroke="#e30112"
              strokeLinecap="round"
              strokeMiterlimit={10}
              strokeWidth="4px"
              points="20.69 37.72 2.83 19.86 20.69 2"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
