import React, {useRef} from 'react';
import {graphql, Link, PageProps} from 'gatsby';
import {Carousel, Col, Pagination, PaginationProps, Row} from 'antd';
import DefaultLayout from '../layouts/default';
import HfSliderHero, {
  ViewModel as SliderHeroViewModel,
} from '../components/hf-slider-hero';
import hero1Image from '../images/solutions-hero-1.jpg';
import hero2Image from '../images/solutions-hero-2.jpg';
import hero3Image from '../images/solutions-hero-3.jpg';
import * as styles from './solutions-list.module.scss';
import coverImage from '../images/solutions-mock-cover.png';
import LeftArrow from '../svgs/left-arrow';
import clsx from 'clsx';
import {CarouselRef} from 'antd/lib/carousel';
import {GatsbyImage} from 'gatsby-plugin-image';
import HfSeo from '../components/hf-seo';

const HERO_DATA: SliderHeroViewModel = [
  {
    title: '唯视智能',
    subTitle: ['一家以振兴中国机器视觉和传感科技为使命的创新型高科技公司'],
    imageUrl: hero1Image,
  },
];
const SolutionsPage = ({
  pageContext,
  location,
}: PageProps<null, SolutionsPageContext>) => {
  const {pagination, categories, solutions, categoryId} = pageContext;
  const carouselRef = useRef<CarouselRef>(null);
  const initialSlide = getInitialSlide(categoryId, categories);
  return (
    <DefaultLayout location={location} className={styles.container}>
      <HfSliderHero vm={HERO_DATA} />
      {/* 类别切换 slider */}
      <section className={styles.categories}>
        <Row justify="center" align="stretch" wrap={false}>
          <Col>
            <div
              className={clsx(styles.arrowCard)}
              onClick={() => {
                carouselRef.current?.prev();
              }}
            >
              <LeftArrow />
            </div>
          </Col>
          <Col flex={1}>
            <Carousel
              ref={carouselRef}
              infinite
              swipe
              slidesToShow={3}
              initialSlide={initialSlide}
              arrows={false}
              dots={false}
              className={styles.carousel}
              responsive={[
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 769,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                  },
                },
              ]}
            >
              {categories.map(category => (
                <div className={styles.category}>
                  <Link
                    className={styles.link}
                    to={`/solutions/category/${category.id}/page/1`}
                  >
                    <div
                      className={clsx(
                        styles.card,
                        categoryId === category.id && styles.active
                      )}
                    >
                      <GatsbyImage
                        className={styles.image}
                        image={
                          category.cover?.imageFile?.childImageSharp
                            .gatsbyImageData
                        }
                        alt={category.name}
                      />

                      <div className={styles.name}>{category.name}</div>
                    </div>
                  </Link>
                </div>
              ))}
            </Carousel>
          </Col>
          <Col>
            <div
              className={clsx(styles.arrowCard, styles.right)}
              onClick={() => {
                carouselRef.current?.next();
              }}
            >
              <LeftArrow />
            </div>
          </Col>
        </Row>
      </section>
      {/* 文章列表 */}
      <div className={styles.articles}>
        {solutions.map(solution => (
          <Link
            className={styles.link}
            to={`/solutions/${solution.id}`}
            key={solution.id}
          >
            <div className={styles.article}>
              <div className={styles.cover}>
                <GatsbyImage
                  className={styles.image}
                  image={
                    solution.cover?.imageFile?.childImageSharp.gatsbyImageData
                  }
                  alt={solution.title}
                />
              </div>
              <div className={styles.content}>
                <div className={styles.title}>{solution.title}</div>
                {solution.category && (
                  <div className={styles.tagCategory}>
                    {solution.category.name}
                  </div>
                )}
                <div className={styles.summary}>{solution.summary}</div>
              </div>
            </div>
          </Link>
        ))}
      </div>
      {/* 分页 */}
      <div className={styles.paging}>
        <ul>
          {Array(pagination.total)
            .fill(0)
            .map((_, index) => {
              const isActive = pagination.current === index + 1;
              let url =
                index === 0 ? '/solutions/' : `/solutions/page/${index + 1}`;
              if (categoryId) {
                url = `/solutions/category/${categoryId}/page/${index + 1}`;
              }
              if (isActive) {
                url = '#';
              }
              return (
                <Link to={url}>
                  <li className={clsx(isActive && styles.active)}>
                    {index + 1}
                  </li>
                </Link>
              );
            })}
        </ul>
      </div>
    </DefaultLayout>
  );
};

export default SolutionsPage;

export const Head = () => <HfSeo title="行业应用 | 唯视智能" />;

function getInitialSlide(
  categoryId: string | null,
  categories: SolutionCategory[]
) {
  if (!categoryId) return 0;
  return categories.findIndex(category => category.id === categoryId);
}
